<template>
  <section>
    <el-form label-width="90px" style="min-width:700px">
      <el-form-item label="">
        支持申论
      </el-form-item>
      <el-form-item label="coursePrefix">
        <el-input v-model="coursePrefix" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="真题ID">
        <el-input v-model="paperId" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="真题名称">
        <el-input v-model="source" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="年份">
        <el-input v-model="year" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="省份">
        <el-input v-model="province" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="学生/教研">
        <el-select v-model="paperMode" placeholder="请选择">
          <el-option v-for="item in paperModeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="primary" @click="onSyncQuestion">同步到题库</el-button>
      </el-form-item>
    </el-form>
    <div class="paper" v-if="paperMode != 'student'">
      {{ fbPaperSheet.name }} <br />
      <div class="question" v-for="(ques, qIndex) in fbUnivSolution.solutions" :key="qIndex">
        <div v-if="ques.materialList">
          <span v-for="(m, mIdx) in ques.materialList" :key="mIdx">
            <span v-html="m.content"></span>
            <br />
          </span>
        </div>
        {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br />
        <h1>fb参考答案</h1>
        <div v-html="ques.reference.content"></div>
        <div v-html="ques.demonstrate.content"></div>
        <div v-html="ques.zstz.content"></div>
      </div>
    </div>
    <div class="paper" v-else>
      {{ fbPaperSheet.name }} <br />
      <div class="question" v-for="(m, mIndex) in fbUnivSolution.materials" :key="'m' + mIndex">
        <span v-html="m.content"></span>
        <br />
      </div>
      <div class="question" v-for="(ques, qIndex) in fbUnivSolution.solutions" :key="qIndex">
        {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br />
      </div>

      <h1>参考答案</h1>
      <div class="question" v-for="(ques, qIndex) in fbUnivSolution.solutions" :key="'a' + qIndex">
        {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br />
        <h1>fb参考答案</h1>
        <div v-html="ques.reference.content"></div>
        <div v-html="ques.demonstrate.content"></div>
        <div v-html="ques.zstz.content"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { crawlerApi } from "@/api/crawlerApi";
import msg from "@/util/msg.js";
export default {
  data() {
    return {
      paperModeOptions: [
        {
          value: "student",
          label: "学生",
        },
        {
          value: "teacher",
          label: "教研",
        },
      ],
      paperMode: "student",
      fbPaperSheet: {},
      fbUnivSolution: {},
      materialDct: {},
      coursePrefix: "shenlun",
      paperId: "204222",
      source: "2023年国家公考《申论》题（副省卷）",
      force: 0,
      year: "2023",
      province: "国家",
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.force) {
      this.force = this.$route.query.force;
    }
  },
  methods: {
    onQuery() {
      if (!this.coursePrefix) {
        msg.warn("coursePrefix 不允许为空");
        return;
      }
      if (!this.isNumeric(this.paperId) || parseInt(this.paperId) <= 0) {
        msg.warn("paperId 必须是大于0的整数");
        return;
      }
      if (!this.source) {
        msg.warn("真题名称 不允许为空");
        return;
      }
      if (!this.province) {
        msg.warn("province 不允许为空");
        return;
      }
      if (!this.isNumeric(this.year) || parseInt(this.year) <= 0) {
        msg.warn("year 必须是大于0的整数");
        return;
      }

      crawlerApi
        .getRealPaper(this.coursePrefix, this.paperId, this.source, this.year, this.province)
        .then((ret) => {
          let { code, data } = ret;
          let usedMaterialId = new Set();
          if (code == 0) {
            console.log("#", ret);
            this.fbPaperSheet = data.fbPaperSheet;
            this.fbUnivSolution = data.fbUnivSolution;
            for (let i = 0; i < this.fbUnivSolution.materials.length; i++) {
              let text = "<p>给定资料" + (i + 1) + "</p>";
              let m = this.fbUnivSolution.materials[i];
              m.content = text + m.content;
            }

            for (let i = 0; i < this.fbUnivSolution.solutions.length; i++) {
              let solution = this.fbUnivSolution.solutions[i];
              solution.questionIndex = i + 1;
              solution.content = solution.content.replaceAll("<p>", "");
              solution.content = solution.content.replaceAll("</p>", "");
              //把材料设置到题目里，方便展示
              if (solution.materialIndexes) {
                let materialList = [];
                for (let idx of solution.materialIndexes) {
                  let m = this.fbUnivSolution.materials[idx];
                  if (!usedMaterialId.has(m.id)) {
                    usedMaterialId.add(m.id); // has
                    materialList.push(m);
                  }
                }
                solution.materialList = materialList;
              }

              for (let j = 0; j < solution.solutionAccessories.length; j++) {
                let sa = solution.solutionAccessories[j];
                if (sa.label == "reference") {
                  solution.reference = sa;
                } else if (sa.label == "demonstrate") {
                  solution.demonstrate = sa;
                } else if (sa.label == "zstz") {
                  solution.zstz = sa;
                }
              }
            }
            console.log("##", this.fbPaperSheet);
            console.log("###", this.fbUnivSolution);
          } else {
            msg.error("出错了:" + ret.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
          msg.error("出错了" + err);
        });
    },
    onSyncQuestion() {
      const hasForce = this.force == 1 ? true : false;
      //subjectCourseId, paperId, userId, sess, force
      crawlerApi
        .syncup(this.coursePrefix, this.paperId, this.source, this.year, this.province, hasForce)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            msg.done("同步成功");
          }
          if (code == 0 && data == false) {
            msg.warn("不允许重复同步");
          }
          if (code != 0) {
            msg.error("出错了" + ret.msg);
            return;
          }
        })
        .catch((err) => {
          msg.error("出错了" + err);
        });
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    },
  },

  watch: {},
};
</script>

<style lang="less" scoped>
.paper {
  text-align: left;
}
.solution {
  text-align: left;
}
</style>
